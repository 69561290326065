import Logo from "../../../../assets/icons/logo.svg";
import { FaCircleNodes } from "react-icons/fa6";
import { FaNeuter } from "react-icons/fa6";
import { IoMdArrowForward } from "react-icons/io";
import { RiMenu3Line } from "react-icons/ri";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./bannerContainerOne.module.css";
import { motion } from "framer-motion";
import TypingTextArray from "../../../../components/typingText/typingTexts";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";

function BannerContainerOne(props) {
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [isMenuBtnClicked, setIsMenuBtnClicked] = useState(false);
  const navigate = useNavigate();

  const handleServiceButtonClick = () => {
    props.scrollToWhatWeDo();
  };

  const handleProductButtonClick = () => {
    props.scrollToOurProduct();
  };

  function btnClickHandler(link) {
    setIsMenuBtnClicked(!isMenuBtnClicked);
    navigate(link);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function menuBtnHandler() {
    setIsMenuBtnClicked(!isMenuBtnClicked);
  }

  return (
    <section className={styles.banner_container}>
      <div className={styles.banner_container_sub}>
        <div className={styles.banner_container_sub_slider}>
          <div className={styles.nav_cont}>
            <div
              className={styles.nav_item_logo}
              style={{ backgroundColor: "#fff" }}
            >
              <div
                className={styles.nav_circle}
                style={{
                  backgroundColor: "#000",
                  height: windowWidth <= 851 ? "1.9rem" : "2.6rem",
                }}
              >
                <img
                  src={Logo}
                  className={styles.logo_img}
                  alt="synerixs-logo"
                />
              </div>
              <p
                style={{ color: "#000", fontWeight: "600", fontSize: "0.9rem" }}
              >
                SYNERIXS
              </p>
            </div>

            {windowWidth > 684 && (
              <div
                className={styles.nav_item}
                onClick={handleServiceButtonClick}
              >
                <div className={styles.nav_circle}>
                  <FaCircleNodes size={20} color="#000" />
                  <p>Services</p>
                </div>
                <p>Services</p>
              </div>
            )}
            {windowWidth > 684 && (
              <div
                className={styles.nav_item}
                onClick={() => btnClickHandler("/about-us")}
              >
                <div className={styles.nav_circle}>
                  <FaNeuter size={20} color="#000" />
                  <p>About Us</p>
                </div>
                <p>About Us</p>
              </div>
            )}
            {windowWidth > 684 && (
              <div
                className={styles.nav_item}
                style={{ marginLeft: "auto", flexDirection: "row-reverse" }}
                onClick={() => btnClickHandler("/contact-us")}
              >
                <div
                  className={styles.nav_circle}
                  style={{ transformOrigin: "right" }}
                >
                  <IoMdArrowForward size={20} color="#000" />
                  <p>Contact Us</p>
                </div>
                <p
                  style={{
                    marginRight: "0rem",
                    marginLeft: "0.1rem",
                  }}
                >
                  Contact Us
                </p>
              </div>
            )}

            {windowWidth <= 684 && (
              <div
                className={styles.menu_item}
                style={{
                  marginLeft: "auto",
                  width: "fit-content",
                  height: "fit-content",
                  padding: "0.3rem",
                  borderRadius: "50%",
                }}
                onClick={menuBtnHandler}
              >
                <div
                  className={styles.nav_circle}
                  style={{ backgroundColor: "#000" }}
                >
                  <RiMenu3Line size={18} color="#fff" />
                </div>
              </div>
            )}
            {isMenuBtnClicked && (
              <div className={styles.menu_overlay} onClick={menuBtnHandler}>
                <div className={styles.menu_container}>
                  <div className={styles.menu_option_container}>
                    <div
                      className={styles.menu_option}
                      onClick={(e) => {
                        menuBtnHandler();
                        handleProductButtonClick();
                      }}
                    >
                      <span>Our Product</span>
                    </div>
                  </div>

                  <div className={styles.menu_option_container}>
                    <div
                      className={styles.menu_option}
                      onClick={(e) => {
                        menuBtnHandler();
                        handleServiceButtonClick();
                      }}
                    >
                      <span>Our Services</span>
                    </div>
                  </div>

                  <div className={styles.menu_option_container}>
                    <div
                      className={styles.menu_option}
                      onClick={() => btnClickHandler("/contact-us")}
                    >
                      <span>Contact Us</span>
                    </div>
                  </div>

                  <div className={styles.menu_option_container}>
                    <div
                      className={styles.menu_option}
                      onClick={() => btnClickHandler("/book-a-call")}
                    >
                      <span>Book A Call</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.text_cont}>
            <motion.p
              animate={{
                opacity: 1,
                y: [600, 0, 50, 0],
              }}
            >
              Let's
            </motion.p>
            <motion.p
              animate={{
                opacity: 1,
                y: [600, 0, 50, 0],
              }}
            >
              Grow
            </motion.p>
            <motion.p
              animate={{
                opacity: 1,
                y: [600, 0, 50, 0],
              }}
              transition={{ duration: 1 }}
            >
              DIGITAL
            </motion.p>
          </div>

          <div className={styles.banner_bottom_cont}>
            <div className={styles.bottom_left_cont}>
              <div className={styles.rounded_cards}>
                <div
                  className={styles.rounded_cards_item}
                  style={{
                    width: windowWidth < 850 ? "14rem" : "15.2rem",
                    minHeight: "2.7rem",
                    justifyContent: "flex-start",
                  }}
                >
                  <TypingTextArray />
                </div>
                <div
                  className={styles.rounded_cards_item_product}
                  onClick={handleProductButtonClick}
                >
                  <div>
                    <p>Product</p>
                  </div>
                </div>
              </div>
              <div className={styles.bottom_left_text_cont}>
                {windowWidth > 684 ? (
                  <p>Commence Your Digital</p>
                ) : (
                  <p>Commence Your Digital Quest</p>
                )}
                <div className={styles.bottom_left_text_cont_sub}>
                  {windowWidth > 684 && <p>Quest</p>}
                  <div
                    className={styles.bottom_left_text_cont_sub_rounded_card}
                  >
                    <div
                      className={
                        styles.bottom_left_text_cont_sub_rounded_card_circle
                      }
                    ></div>
                    <div
                      className={
                        styles.bottom_left_text_cont_sub_rounded_card_circle
                      }
                    ></div>
                    <div
                      className={
                        styles.bottom_left_text_cont_sub_rounded_card_circle
                      }
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            {windowWidth > 1086 && (
              <div className={styles.banner_bottom_middle}>
                <p>
                  Step into the realm of digital marketing expertise, where
                  seasoned strategists craft tailored solutions to amplify
                  online visibility and drive sustainable growth.
                </p>
              </div>
            )}

            <div className={styles.banner_bottom_right}>
              <div className={styles.banner_bottom_right_card}>
                <p>From</p>
                <p>100%</p>
                <p>Offline market</p>
              </div>
              <div className={styles.banner_bottom_right_card}>
                <p>To</p>
                <p>1000%</p>
                <p>Online market</p>
              </div>
            </div>
          </div>

          {windowWidth <= 1086 && (
            <div className={styles.banner_bottom_middle}>
              <p>
                Step into the realm of digital marketing expertise, where
                seasoned strategists craft tailored solutions to amplify online
                visibility and drive sustainable growth.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default BannerContainerOne;
