import { PiStrategyLight } from "react-icons/pi";
import { PiCubeFocusLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import WhoWeAre from "../../../../assets/images/whoWeAre.svg";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";
import styles from "./whoWeAreContainer.module.css";

gsap.registerPlugin(ScrollTrigger);

function WhoWeAreContainer() {
  const navigate = useNavigate();

  const boxRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      boxRef.current,
      { opacity: 0, x: -400 },
      {
        opacity: 1,
        x: 0,
        // ease: "bounce.out",
        scrollTrigger: {
          trigger: boxRef.current,
          start: "top 90%",
          end: "top 40%",
          scrub: true,
          // markers: true,
        },
      }
    );
  }, []);

  function btnClickHandler() {
    navigate("/book-a-call");
  }

  return (
    <section className={styles.who_container}>
      <div className={styles.who_container_sub}>
        <div className={styles.who_container_sub_img} ref={boxRef}>
          <img src={WhoWeAre} alt="who-we-are-image" />
        </div>
        <div className={styles.who_container_sub_content}>
          <div className={styles.who_container}>
            <span className={styles.who_text}>Who We Are</span>
          </div>

          <div className={styles.description_container}>
            <p className={styles.description_text}>
              Crafting Your Brand's Digital Story: Experts in Marketing
              Excellence
            </p>
          </div>

          <p className={styles.small_desciption_text}>
            Shaping Your Brand's Digital Narrative: Pioneers in Marketing
            Excellence. Our tailored strategies sculpt unique online identities,
            driving impactful engagement and measurable success. Let us craft
            your story, empowering your brand's journey to digital eminence.
          </p>

          <div className={styles.who_btn_container}>
            <div className={styles.features_cont}>
              <PiStrategyLight color="#55e6a5" size={70} />
              <div className={styles.features_text_cont}>
                <p>Innovation & Strategy</p>
                <p>
                  Harnessing creativity and strategic planning to drive
                  innovation and achieve business objectives with precision and
                  purpose.
                </p>
              </div>
            </div>
            <div className={styles.features_cont}>
              <PiCubeFocusLight color="#55e6a5" size={70} />
              <div className={styles.features_text_cont}>
                <p>Impact & Results</p>
                <p>
                  Driving measurable outcomes and tangible effects, translating
                  strategies into real-world success, maximizing impact across
                  all marketing endeavors.
                </p>
              </div>
            </div>
            <ButtonComponent
              text={"Book A Call"}
              style={styles.who_btn_container_signup}
              handler={btnClickHandler}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoWeAreContainer;
