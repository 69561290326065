import Logo from "../../../../assets/icons/logo.svg";
import ContactImg from "../../../../assets/images/contact-us.svg";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaTelegramPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./footerContainer.module.css";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";

function FooterContainer() {
  const navigate = useNavigate();

  function btnClickHandler(link) {
    navigate(link);
  }

  const handleFacebookClick = () => {
    window.location.href =
      "https://www.facebook.com/profile.php?id=61558703919526&mibextid=ZbWKwL";
  };

  const handleInstagramClick = () => {
    window.location.href =
      "https://www.instagram.com/invites/contact/?i=1lg80y367in4x&utm_content=udzhoxa";
  };

  const handleLinkedinClick = () => {
    window.location.href =
      "https://www.linkedin.com/in/synerixs-757392307?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";
  };

  return (
    <section className={styles.footer_container}>
      <div className={styles.footer_container_sub}>
        <div className={styles.footer}>
          <div className={styles.footer_top}>
            <div className={styles.footer_top_sub1}>
              <div className={styles.name_address}>
                <div className={styles.logo_cont}>
                  <img src={Logo} />
                  <h2>Synerixs</h2>
                </div>

                <div className={styles.address_cont}>
                  <p>Level up your brand's digital game with us.</p>
                </div>

                <div className={styles.gst_cont}>
                  <span>GSTIN - </span>
                  <p>33BUBPK4992F1ZM</p>
                </div>
              </div>

              <div className={styles.legals}>
                <span>Legals</span>
                <p onClick={() => navigate("/terms-of-service")}>
                  Terms of service
                </p>
                <p onClick={() => navigate("/privacy-policy")}>
                  Privacy policy
                </p>
                <p onClick={() => navigate("/faq")}>FAQ</p>
              </div>

              <div className={styles.legals}>
                <span>Company</span>
                <p onClick={() => navigate("/")}>Home</p>
                <p onClick={() => navigate("/about-us")}>About us</p>
              </div>

              <div className={styles.social_media}>
                <div className={styles.icons}>
                  <div className={styles.icon} onClick={handleLinkedinClick}>
                    <FaLinkedinIn size={18} color="#000" />
                  </div>
                  <div className={styles.icon} onClick={handleFacebookClick}>
                    <FaFacebookF size={18} color="#000" />
                  </div>
                  <div className={styles.icon} onClick={handleInstagramClick}>
                    <GrInstagram size={18} color="#000" />
                  </div>
                </div>

                <div className={styles.mail_mbl_cont}>
                  <div className={styles.mail_mbl}>
                    <MdEmail size={18} color="#fff" />
                    <a href="mailto:support@synerixs.com" target="_blank">
                      support@synerixs.com
                    </a>
                  </div>

                  <div className={styles.mail_mbl}>
                    <FaPhoneAlt size={16} color="#fff" />
                    <a href="tel:+919629239759">+91 9629239759</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer_bottom}>
            <p
              style={{ color: "#55e6a5", fontWeight: "500", cursor: "pointer" }}
            >
              Synerixs.{" "}
            </p>
            <p>&copy; 2024. All rights reserved.</p>
          </div>

          <div className={styles.contact_us_float}>
            <div className={styles.text_btn}>
              <p>
                Maximize your digital presence and drive growth with our
                integrated solutions. Elevate your brand now!
              </p>
              <ButtonComponent
                text={"Book A Call"}
                style={styles.contact_btn}
                handler={() => btnClickHandler("/book-a-call")}
              />
            </div>

            <div className={styles.contact_img_cont}>
              <img src={ContactImg} alt="contact-us-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FooterContainer;
