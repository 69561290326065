import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "../privacyPolicy/privacyPolicyPage.module.css";

function FaqPage() {
  const navigate = useNavigate();

  function backBtnHandler() {
    navigate("/");
  }

  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_btn_cont}>
        <ButtonComponent
          text={"Back to home"}
          style={styles.btn}
          handler={backBtnHandler}
        />
      </div>
      <div className={styles.top_image_container}>
        <span>Frequently Asked Questions</span>
        <span>
          Explore answers on digital marketing, app development, design, and
          more with Synerixs FAQ.
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span>What services does Synerixs offer?</span>
          <span>
            Synerixs provides a comprehensive range of digital marketing
            services tailored to suit your business needs. Our offerings include
            search engine optimization (SEO), social media marketing (SMM),
            pay-per-click (PPC) advertising, content marketing, email marketing,
            web and mobile app development, poster design, and funnel page
            creation.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>How can Synerixs help my business grow?</span>
          <span>
            We specialize in crafting customized digital marketing strategies
            designed to enhance your online presence, drive traffic, generate
            leads, and ultimately increase conversions. By leveraging the latest
            tools and techniques, we ensure that your brand stands out in the
            competitive digital landscape, helping you achieve sustainable
            growth and success.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>What industries does Synerixs work with?</span>
          <span>
            Synerixs works with businesses across various industries, including
            but not limited to e-commerce, healthcare, technology, finance,
            education, hospitality, and retail. Our adaptable approach allows us
            to cater to the unique requirements and objectives of diverse
            clients.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>How does Synerixs handle mobile and web app development?</span>
          <span>
            At Synerixs, we have a dedicated team of experienced developers
            proficient in both mobile and web technologies. From concept to
            deployment, we collaborate closely with our clients to ensure that
            their apps are not only visually appealing but also user-friendly,
            functional, and optimized for performance across different devices
            and platforms.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>
            Can Synerixs assist with designing posters and funnel pages?
          </span>
          <span>
            Yes, absolutely! Our creative team possesses the expertise to design
            eye-catching posters that effectively convey your brand message and
            captivate your target audience. Additionally, we specialize in
            creating high-converting funnel pages that guide users through the
            sales process, maximizing engagement and conversions.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>
            How long does it take to see results from Synerixs' digital
            marketing efforts?
          </span>
          <span>
            The timeframe for seeing tangible results can vary depending on
            factors such as the competitiveness of your industry, the scope of
            your campaign, and your specific goals. However, we strive to
            deliver measurable results within a reasonable timeframe and provide
            regular updates and insights to keep you informed of our progress.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Is Synerixs' pricing transparent?</span>
          <span>
            Yes, transparency is one of our core values. We believe in providing
            clear and competitive pricing tailored to your budget and
            requirements. Before commencing any project, we ensure that you
            fully understand the costs involved and the value you can expect to
            receive from our services
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>How can I get started with Synerixs?</span>
          <span>
            Getting started with Synerixs is simple! Reach out to us through our
            website or contact us directly to schedule a consultation. During
            the consultation, we'll discuss your goals, challenges, and how our
            services can help you achieve success. From there, we'll develop a
            customized strategy and action plan to kickstart your digital
            marketing journey with Synerixs.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Still have questions?</span>
          <span>
            If you have any further inquiries or would like more information
            about our services, don't hesitate to contact us. You can reach out
            to us via email at support@synerixs.com, through WhatsApp at +91
            9629239759, or give us a call at +91 9629239759. Our team is here to
            assist you every step of the way.
          </span>
        </div>
      </div>
    </section>
  );
}

export default FaqPage;
