import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "../privacyPolicy/privacyPolicyPage.module.css";

function TermsOfServicesPage() {
  const navigate = useNavigate();

  function backBtnHandler() {
    navigate("/");
  }
  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_btn_cont}>
        <ButtonComponent
          text={"Back to home"}
          style={styles.btn}
          handler={backBtnHandler}
        />
      </div>
      <div className={styles.top_image_container}>
        <span>We care about your privacy</span>
        <span>
          Synerixs values trust. Our Terms govern website rules. By accessing,
          you agree. Please review for clarity
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span></span>
          <span>
            Welcome to Synerixs, a comprehensive digital marketing agency
            dedicated to providing top-notch services to our clients. By
            accessing or using any of our services, including but not limited to
            digital marketing, mobile and web app development, poster design,
            and funnel page creation, you agree to be bound by the following
            terms and conditions:
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Acceptance of Terms</span>
          <span>
            By accessing our services, you agree to be bound by these Terms of
            Service, as well as any additional terms and conditions that apply
            to specific services offered by Synerixs. If you do not agree to
            these terms, please refrain from using our services.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Services</span>
          <span>
            Synerixs offers a range of digital marketing services, including but
            not limited to search engine optimization (SEO), social media
            marketing, pay-per-click (PPC) advertising, email marketing, content
            creation, mobile and web app development, poster design, and funnel
            page creation. Our services are designed to help businesses enhance
            their online presence and achieve their marketing goals.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Client Responsibilities</span>
          <span>
            Clients are responsible for providing accurate and up-to-date
            information necessary for the delivery of our services. Clients must
            also adhere to any guidelines or requirements specified by Synerixs
            during the course of the project.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Payment</span>
          <span>
            Payment terms will be outlined in the agreement between Synerixs and
            the client. Clients are responsible for making timely payments for
            the services rendered by Synerixs. Failure to make payments may
            result in the suspension or termination of services.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Intellectual Property</span>
          <span>
            All intellectual property rights related to the services provided by
            Synerixs, including but not limited to designs, content, and
            software, are owned by Synerixs unless otherwise specified in
            writing. Clients may not reproduce, modify, or distribute any
            materials provided by Synerixs without prior consent.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Confidentiality</span>
          <span>
            Synerixs and the client agree to maintain the confidentiality of any
            proprietary or sensitive information shared during the course of the
            project. This includes but is not limited to business strategies,
            financial information, and trade secrets.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Limitation of Liability</span>
          <span>
            Synerixs shall not be liable for any direct, indirect, incidental,
            special, or consequential damages arising out of or in any way
            connected with the use of our services. This includes but is not
            limited to loss of profits, data, or business interruption.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Refund Policy</span>
          <span>
            At Synerixs, we prioritize client satisfaction and strive to deliver
            exceptional services. In the event that a client is not completely
            satisfied with our services, we offer a refund policy. Refunds will
            be granted to clients who request cancellation of services within 15
            days of the initial payment. To initiate a refund, clients must
            submit a written request via email to our customer support team at
            support@synerixs.com. Refunds will be processed promptly within 5
            days of receiving the refund request, using the original method of
            payment. It's important to note that after the 15-day period has
            elapsed, no refunds will be issued for services rendered. However,
            certain services may be exempt from our refund policy, and clients
            will be informed of such exemptions prior to the commencement of the
            project. Should you have any questions or concerns regarding our
            refund policy, please do not hesitate to reach out to our customer
            support team at support@synerixs.com. We appreciate your trust in
            Synerixs for your digital marketing needs.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Governing Law</span>
          <span>
            These Terms of Service shall be governed by and construed in
            accordance with the laws. Any disputes arising out of or in
            connection with these terms shall be subject to the exclusive
            jurisdiction of the courts.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Modifications</span>
          <span>
            Synerixs reserves the right to update or modify these Terms of
            Service at any time without prior notice. Any changes will be
            effective immediately upon posting on our website. It is the
            responsibility of the client to review these terms periodically for
            changes
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Contact Us</span>
          <span>
            If you have any questions or concerns about these Terms of Service,
            please contact us at support@synerixs.com.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span></span>
          <span>
            By using our services, you acknowledge that you have read,
            understood, and agreed to these Terms of Service. Thank you for
            choosing Synerixs as your digital marketing partner.
          </span>
        </div>

        <div className={styles.head_date_container}>
          <span>Last updated :</span>
          <span> April 30, 2024</span>
        </div>
      </div>
    </section>
  );
}

export default TermsOfServicesPage;
