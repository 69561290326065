import { useState, useEffect, forwardRef } from "react";
import { FaMeta } from "react-icons/fa6";
import { SiGoogleads } from "react-icons/si";
import { TbDeviceMobileCode } from "react-icons/tb";
import { TbDeviceDesktopCode } from "react-icons/tb";
import { PiFunnelBold } from "react-icons/pi";
import { AiOutlineAntDesign } from "react-icons/ai";
import WhatWeDo from "../../../../assets/images/whatWeDo1.svg";
import { motion, useScroll, useTransform } from "framer-motion";
import styles from "./whatWeDoContainer.module.css";
import commonStyles from "../../../mainStyles.module.css";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";

const WhatWeDoContainer = forwardRef((props, ref) => {
  const [windowWidth, setWindowWidth] = useState(undefined);
  const { scrollY } = useScroll();

  const imgY = useTransform(scrollY, [993, 1400], [400, 0]);
  const imgOpacity = useTransform(scrollY, [993, 1400], [0, 1]);
  const imgRotate = useTransform(scrollY, [993, 1400], [65, 0]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleProductButtonClick = () => {
    props.scrollToOurProduct();
  };

  return (
    <section className={styles.what_container} ref={ref}>
      <div className={styles.what_container_main}>
        <div
          className={`${commonStyles.column_flex_box} ${styles.what_container_sub}`}
        >
          <div className={styles.what_container_img}></div>
          <div
            className={`${commonStyles.row_flex_box} ${styles.what_container_sub_top}`}
          >
            {windowWidth > 849 && (
              <div
                className={`${commonStyles.column_flex_box} ${styles.what_container_sub_top_text_cont}`}
              >
                <p>What</p>
                <p>We</p>
                <p>Do</p>
                <p>
                  Transforming businesses with targeted marketing and immersive
                  app development.
                </p>
              </div>
            )}
            {windowWidth <= 849 && (
              <div
                className={`${commonStyles.column_flex_box} ${styles.what_container_sub_top_text_cont_new}`}
              >
                <p>What We Do</p>
                <p>
                  Transforming businesses with targeted marketing and immersive
                  app development.
                </p>
              </div>
            )}
            {windowWidth > 849 && (
              <motion.div
                className={`${commonStyles.column_flex_box} ${styles.img_cont_top}`}
                style={{
                  y: imgY,
                  opacity: imgOpacity,
                  rotate: imgRotate,
                }}
              >
                <img src={WhatWeDo} alt="what-we-do-image" />
              </motion.div>
            )}
            {windowWidth > 849 && (
              <div
                className={`${commonStyles.column_flex_box} ${styles.view_product}`}
              >
                <p>See What We've Created!</p>
                {/* <p>Witness Our Digital Magic!</p> */}
                <ButtonComponent
                  text={"View"}
                  style={styles.view_btn}
                  handler={handleProductButtonClick}
                />
              </div>
            )}
          </div>
          {windowWidth <= 849 && (
            <div
              className={`${commonStyles.row_flex_box} ${styles.what_container_sub_top_new}`}
            >
              <div
                className={`${commonStyles.column_flex_box} ${styles.img_cont_top}`}
              >
                <img src={WhatWeDo} alt="what-we-do-image" />
              </div>

              <div
                className={`${commonStyles.column_flex_box} ${styles.view_product}`}
              >
                <p>See What We've Created!</p>
                {/* <p>Witness Our Digital Magic!</p> */}
                <ButtonComponent text={"View"} style={styles.view_btn} />
              </div>
            </div>
          )}
          <div
            className={`${commonStyles.row_flex_box} ${styles.what_container_sub_bottom}`}
          >
            <div
              className={`${commonStyles.column_flex_box} ${styles.what_container_sub_bottom_text_cont}`}
            >
              <p>Digital Artisans at</p>
              <p>Your Service</p>
              <div className={styles.line}>
                <div className={styles.line_in}></div>
              </div>
            </div>

            <div
              className={`${commonStyles.row_flex_box} ${styles.skills_cont_bottom}`}
            >
              <div
                className={`${commonStyles.row_flex_box} ${styles.skill_cont}`}
              >
                <div className={styles.skill_img_cont}>
                  <FaMeta color="#55e6a5" size={30} />
                </div>
                <div
                  className={`${commonStyles.column_flex_box} ${styles.skill_text_cont}`}
                >
                  <p>Meta Ads</p>
                  <p>Digital Marketing</p>
                </div>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.skill_cont}`}
              >
                <div className={styles.skill_img_cont}>
                  <SiGoogleads color="#55e6a5" size={30} />
                </div>
                <div
                  className={`${commonStyles.column_flex_box} ${styles.skill_text_cont}`}
                >
                  <p>Google Ads</p>
                  <p>Digital Marketing</p>
                </div>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.skill_cont}`}
              >
                <div className={styles.skill_img_cont}>
                  <TbDeviceDesktopCode color="#55e6a5" size={30} />
                </div>
                <div
                  className={`${commonStyles.column_flex_box} ${styles.skill_text_cont}`}
                >
                  <p>Web App</p>
                  <p>Development</p>
                </div>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.skill_cont}`}
              >
                <div className={styles.skill_img_cont}>
                  <TbDeviceMobileCode color="#55e6a5" size={30} />
                </div>
                <div
                  className={`${commonStyles.column_flex_box} ${styles.skill_text_cont}`}
                >
                  <p>Mobile App</p>
                  <p>Development</p>
                </div>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.skill_cont}`}
              >
                <div className={styles.skill_img_cont}>
                  <PiFunnelBold color="#55e6a5" size={30} />
                </div>
                <div
                  className={`${commonStyles.column_flex_box} ${styles.skill_text_cont}`}
                >
                  <p>Funnel Page</p>
                  <p>Development</p>
                </div>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.skill_cont}`}
              >
                <div className={styles.skill_img_cont}>
                  <AiOutlineAntDesign color="#55e6a5" size={30} />
                </div>
                <div
                  className={`${commonStyles.column_flex_box} ${styles.skill_text_cont}`}
                >
                  <p>Poster Design</p>
                  <p>Design</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default WhatWeDoContainer;
