import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "./privacyPolicyPage.module.css";

function PrivacyPolicyPage() {
  const navigate = useNavigate();

  function backBtnHandler() {
    navigate("/");
  }
  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_btn_cont}>
        <ButtonComponent
          text={"Back to home"}
          style={styles.btn}
          handler={backBtnHandler}
        />
      </div>
      <div className={styles.top_image_container}>
        <span>We care about your privacy</span>
        <span>
          Your privacy is important to us at Synerixs. We respect your privacy
          regarding any information we may collect from you across our website.
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span></span>
          <span>
            Synerixs is dedicated to safeguarding the privacy of our users. This
            Privacy Policy elucidates the types of personal information we
            gather when you engage with our website www.synerixs.com, how we
            employ and secure that information, and your preferences concerning
            your personal data.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Information we collect</span>
          <span>
            When you navigate our website, certain information may be gathered
            automatically, including your IP address, browser type, operating
            system, referral URLs, access times, and pages visited.
            Additionally, we may accumulate data about your interactions and
            activities on the site through the utilization of cookies, web
            beacons, and analogous technologies. If you avail yourself of our
            digital marketing services, mobile or web app development services,
            or engage in poster and funnel page design, we may collect personal
            information such as your name, email address, mailing address and
            phone number.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>How We Utilize Your Information</span>
          <span>
            We may utilize the collected information for various purposes,
            including: Provision and personalization of our services, including
            digital marketing campaigns and app development. Correspondence with
            you, addressing your inquiries, and delivering customer support.
            Analysis and enhancement of our website and services. Detection,
            prevention, and resolution of technical issues and security
            vulnerabilities. Compliance with legal obligations and enforcement
            of our terms and policies.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Data security</span>
          <span>
            We prioritize the security of your personal information and
            implement appropriate technical and organizational measures to
            safeguard it against unauthorized access, disclosure, alteration, or
            destruction.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Third-party services</span>
          <span>
            In order to operate the website and deliver our services
            effectively, we may engage third-party services such as payment
            processors and analytics providers. These third parties may have
            access to your personal information solely for the purpose of
            performing these tasks on our behalf and are obligated not to
            disclose or utilize it for any other intent.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Your choices</span>
          <span>
            While you have the option to abstain from providing certain personal
            information, it may restrict your ability to utilize specific
            features of the website or access certain services. You may opt-out
            of receiving promotional communications from us by following the
            unsubscribe instructions provided in the communication or by
            contacting us directly.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Changes to this privacy policy</span>
          <span>
            We may revise this Privacy Policy periodically to reflect
            alterations in our practices or legal obligations. We will notify
            you of any significant changes by posting the updated Privacy Policy
            on the website and updating the "Last Updated" date at the top of
            this page.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Contact Us</span>
          <span>
            If you have any inquiries or concerns regarding this Privacy Policy
            or our practices concerning your personal information, please feel
            free to contact us at support@synerixs.com.
          </span>
        </div>

        <div className={styles.head_date_container}>
          <span>Last updated :</span>
          <span> April 30, 2024</span>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicyPage;
