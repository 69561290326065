import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Homepage from "./pages/homepage/homepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/layout";
import styles from "../src/components/layout/main-navigation.module.css";
import ContactUsPage from "./pages/generalPages/contactUsPage/contactUsPage";
import PrivacyPolicyPage from "./pages/generalPages/privacyPolicy/privacyPolicyPage";
import TermsOfServicesPage from "./pages/generalPages/termsofServices/termsOfServicesPage";
import FaqPage from "./pages/generalPages/faq/faqPage";
import AboutUsPage from "./pages/generalPages/aboutUsPage/aboutUsPage";
import BookCallPage from "./pages/generalPages/bookCallPage/bookCallPage.js";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Layout>
        {location.pathname === "/" && (
          <div className={styles.zoom_cont}>
            <div className={styles.zooming_circle}></div>
          </div>
        )}
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/contact-us" element={<ContactUsPage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            exact
            path="/terms-of-service"
            element={<TermsOfServicesPage />}
          />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route exact path="/about-us" element={<AboutUsPage />} />
          <Route exact path="/book-a-call" element={<BookCallPage />} />
          <Route exact path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        toastStyle={{ backgroundColor: "#242627", color: "#c9c8c7" }}
      />
    </div>
  );
}

export default App;
