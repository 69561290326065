import { useState, useEffect } from "react";
import charteeyImg from "../../../../assets/images/charteey-mbl.svg";
import styles from "./ourProductContainer.module.css";
import commonStyles from "../../../mainStyles.module.css";
import { motion, useScroll, useTransform } from "framer-motion";
import ButtonComponent from "../../../../components/buttonComponent/buttonComponent";
import { forwardRef } from "react";

const OurProductContainer = forwardRef((props, ref) => {
  const [windowWidth, setWindowWidth] = useState(undefined);
  const { scrollY } = useScroll();
  const [scrollX, setScrollX] = useState(0);
  const [scrollYy, setScrollYy] = useState(0);

  const imgY = useTransform(scrollY, [2880, 3175], [400, 0]);
  const imgOpacity = useTransform(scrollY, [2880, 3175], [0, 1]);

  const textLeftX = useTransform(scrollY, [2880, 3175], [-400, 0]);
  const textLeftOpacity = useTransform(scrollY, [2880, 3175], [0, 1]);

  const textRightX = useTransform(scrollY, [2880, 3175], [400, 0]);
  const textRightOpacity = useTransform(scrollY, [2880, 3175], [0, 1]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollX(window.scrollX);
      setScrollYy(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // console.log(scrollYy);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVisitBtnClick = () => {
    window.location.href = "https://www.charteey.com";
  };

  return (
    <section className={styles.product_container} ref={ref}>
      <div className={styles.product_container_main}>
        <div
          className={`${commonStyles.column_flex_box} ${styles.product_container_sub}`}
        >
          <p>Our Product</p>
          <div className={styles.description_cont}>
            <p>Your ultimate trading companion for insights and expertise.</p>
          </div>

          <motion.div
            className={`${commonStyles.row_flex_box} ${styles.product_container_sub_div} `}
          >
            <motion.p
              style={{
                x: textLeftX,
                opacity: textLeftOpacity,
              }}
            >
              Char
            </motion.p>
            <motion.p
              style={{
                x: textRightX,
                opacity: textRightOpacity,
              }}
            >
              teey
            </motion.p>
            <motion.img
              src={charteeyImg}
              alt="product-image"
              style={{
                y: imgY,
                opacity: imgOpacity,
              }}
            />
            {windowWidth > 720 && (
              <>
                <div className={styles.description_cont_two}>
                  <p>Elevating your trading game with precision insights.</p>
                  <ButtonComponent
                    text={"Visit site"}
                    style={styles.view_btn}
                    handler={handleVisitBtnClick}
                  />
                </div>

                <div className={styles.description_cont_two_line}></div>
              </>
            )}
          </motion.div>

          <div
            className={`${commonStyles.row_flex_box} ${styles.product_container_sub_div_two} `}
          >
            <p>
              Unlock the power of trading on-the-go with Charteey's mobile app –
              download now for expert insights at your fingertips.
            </p>

            <div className={styles.google_play}>
              <a href="https://play.google.com/store/apps/details?id=com.ram37.TradersSpotRN&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
          {windowWidth <= 720 && (
            <div
              className={`${commonStyles.row_flex_box} ${styles.product_container_sub_div_two} `}
            >
              <p>Elevating your trading game with precision insights.</p>
              <ButtonComponent
                text={"Visit site"}
                style={styles.view_btn}
                handler={handleVisitBtnClick}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default OurProductContainer;
