import Marquee from "react-fast-marquee";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import styles from "./skillsContainer.module.css";
import commonStyles from "../../../mainStyles.module.css";

function SkillsContainer() {
  return (
    <section className={styles.skills_container}>
      <div className={styles.skills_container_main}>
        <div
          className={`${commonStyles.row_flex_box} ${styles.skills_container_sub}`}
        >
          <Marquee pauseOnHover={true} style={{ overflow: "hidden" }}>
            <div
              className={`${commonStyles.row_flex_box} ${styles.marquee_sub}`}
            >
              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>META BUSINESS SUITE</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>GOOGLE ADS MANAGER</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>HTML</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>CSS</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>JAVA SCRIPT</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>REACT JS</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>NEXT JS</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>NODE JS</p>
              </div>

              <div
                className={`${commonStyles.row_flex_box} ${styles.marquee_text_cont}`}
              >
                <Sentry color="#55e6a5" />
                <p>EXPRESS JS</p>
              </div>
            </div>
          </Marquee>
        </div>
      </div>
    </section>
  );
}

export default SkillsContainer;
