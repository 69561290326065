import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CallPostApiServices } from "../../../webservices/apiCalls";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "./contactUsPage.module.css";
import { useState } from "react";

function ContactUsPage() {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredMblNo, setEnteredMblNo] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();

  function backBtnHandler() {
    navigate("/");
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!enteredEmail && !enteredMblNo && !enteredDescription) {
      toast.error("Please enter all the details");
      return;
    }

    if (!enteredEmail || !emailRegex.test(enteredEmail)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!enteredMblNo || enteredMblNo.length !== 10) {
      toast.error("Please enter a valid mobile number.");
      return;
    }

    if (!enteredDescription) {
      toast.error("Please tell us what help you seek.");
      return;
    }

    setBtnLoader(true);

    CallPostApiServices(
      `/user/contact-synerixs`,
      {
        email: enteredEmail,
        mobileNo: enteredMblNo,
        description: enteredDescription,
      },
      (response) => {
        if (response.status === 200) {
          setEnteredEmail("");
          setEnteredMblNo("");
          setEnteredDescription("");
          setBtnLoader(false);
          toast.success("We will reach you shortly, Thank you!");
        }
      },
      (err) => {
        console.log("err sending otp on registration");
        setBtnLoader(false);
        if (err.response?.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }

        toast.error("Server Error");
      }
    );
  }

  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_btn_cont}>
        <ButtonComponent
          text={"Back to home"}
          style={styles.btn}
          handler={backBtnHandler}
        />
      </div>
      <div className={styles.top_image_container}>
        <span>Contact Us</span>
      </div>
      <div className={styles.form_content_container}>
        <div className={styles.form_content_left}>
          <p>Get in touch</p>
          <p>Have any Project Plan In your Mind?</p>
          <p>
            Share your project vision with us, and together we'll strategize,
            design, and execute a tailored plan to bring your ideas to life,
            maximizing your digital presence and success.
          </p>

          <p>
            Feel empowered to reach out to us at any time. We're here to assist
            you with all your digital marketing and development needs.
          </p>

          <div className={styles.mail_mbl_cont}>
            <div className={styles.mail_mbl}>
              <MdEmail size={18} color="#55e6a5" />
              <a href="mailto:charteey.team@gmail.com" target="_blank">
                support@synerixs.com
              </a>
            </div>

            <div className={styles.mail_mbl}>
              <FaPhoneAlt size={16} color="#55e6a5" />
              <a href="tel:+918248189924">+91 8248189924</a>
            </div>
          </div>
        </div>
        <div className={styles.form_content_right}>
          <form>
            <div className={styles.form_group}>
              <label className={styles.label} htmlFor="email">
                Email
              </label>
              <input
                className={styles.input_field}
                type="email"
                id="email"
                name="email"
                required
                value={enteredEmail || ""}
                onChange={(event) => setEnteredEmail(event.target.value)}
              />
            </div>
            <div className={styles.form_group}>
              <label className={styles.label} htmlFor="mobile_number">
                Mobile number
              </label>
              <input
                className={styles.input_field}
                type="tel"
                id="mobile_number"
                name="mobile_number"
                required
                value={enteredMblNo}
                onChange={(event) => setEnteredMblNo(event.target.value)}
              />
            </div>

            <div className={styles.form_group}>
              <label className={styles.label} htmlFor="description">
                How can we help you ?
              </label>
              <textarea
                className={styles.input_field}
                id="description"
                name="description"
                required
                rows="6"
                value={enteredDescription}
                onChange={(event) => setEnteredDescription(event.target.value)}
              />
            </div>
            <ButtonComponent
              handler={formSubmitHandler}
              text={"Submit"}
              indicator={btnLoader}
              disabled={btnLoader}
              style={styles.submit_button}
            />
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUsPage;
