import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CallPostApiServices } from "../../../webservices/apiCalls";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "./bookCallPage.module.css";
import { useState } from "react";

function BookCallPage() {
  const [enteredName, setEnteredName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredMblNo, setEnteredMblNo] = useState("");
  const [enteredCompanyName, setEnteredCompanyName] = useState("");
  const [enteredWebsiteUrl, setEnteredWebsiteUrl] = useState("");
  const [interestedIn, setInterestedIn] = useState("");
  const [budgetRange, setBudgetRange] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();

  function backBtnHandler() {
    navigate("/");
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      !enteredName &&
      !enteredEmail &&
      !enteredMblNo &&
      !enteredCompanyName &&
      !enteredWebsiteUrl &&
      !interestedIn &&
      !budgetRange &&
      !instaUrl &&
      !enteredDescription
    ) {
      toast.error("Please enter all the details");
      return;
    }

    if (!enteredName) {
      toast.error("Please enter your name.");
      return;
    }

    if (!enteredEmail || !emailRegex.test(enteredEmail)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!enteredMblNo || enteredMblNo.length !== 10) {
      toast.error("Please enter a valid mobile number.");
      return;
    }

    if (!enteredCompanyName) {
      toast.error("Please enter your company name.");
      return;
    }

    if (!enteredWebsiteUrl) {
      toast.error("Please enter your website URL.");
      return;
    }

    if (!interestedIn) {
      toast.error("Please select your interest.");
      return;
    }

    if (!budgetRange) {
      toast.error("Please select your budget.");
      return;
    }

    if (!instaUrl) {
      toast.error("Please enter your website URL.");
      return;
    }

    if (!enteredDescription) {
      toast.error("Please give some message.");
      return;
    }

    setBtnLoader(true);

    CallPostApiServices(
      `/user/book-a-call-synerixs`,
      {
        name: enteredName,
        email: enteredEmail,
        mobileNo: enteredMblNo,
        companyName: enteredCompanyName,
        websiteUrl: enteredWebsiteUrl,
        interestedIn: interestedIn,
        budgetRange: budgetRange,
        instaUrl: instaUrl,
        description: enteredDescription,
      },
      (response) => {
        if (response.status === 200) {
          setEnteredName("");
          setEnteredEmail("");
          setEnteredMblNo("");
          setEnteredCompanyName("");
          setEnteredWebsiteUrl("");
          setInterestedIn("");
          setBudgetRange("");
          setInstaUrl("");
          setEnteredDescription("");
          setBtnLoader(false);
          toast.success("We will reach you shortly, Thank you!");
        }
      },
      (err) => {
        console.log("err sending otp on registration");
        setBtnLoader(false);
        if (err.response?.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }

        toast.error("Server Error");
      }
    );
  }

  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_btn_cont}>
        <ButtonComponent
          text={"Back to home"}
          style={styles.btn}
          handler={backBtnHandler}
        />
      </div>

      <div className={styles.form_content_container}>
        <div className={styles.form_content_sub}>
          <form className={styles.form}>
            <div className={styles.heading_cont}>
              <p>Book A Free Call</p>
            </div>
            <div className={styles.inputs_cont}>
              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="name">
                  Name
                  <span>*</span>
                </label>
                <input
                  className={styles.input_field}
                  placeholder="Your Name"
                  type="text"
                  id="name"
                  name="name"
                  required
                  value={enteredName || ""}
                  onChange={(event) => setEnteredName(event.target.value)}
                />
              </div>

              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="email">
                  Email
                  <span>*</span>
                </label>
                <input
                  className={styles.input_field}
                  placeholder="Your Email"
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={enteredEmail || ""}
                  onChange={(event) => setEnteredEmail(event.target.value)}
                />
              </div>

              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="mobile_number">
                  Mobile number
                  <span>*</span>
                </label>
                <input
                  className={styles.input_field}
                  placeholder="Your Mobile Number"
                  type="tel"
                  id="mobile_number"
                  name="mobile_number"
                  required
                  value={enteredMblNo || ""}
                  onChange={(event) => setEnteredMblNo(event.target.value)}
                />
              </div>
            </div>

            <div className={styles.inputs_cont}>
              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="cname">
                  Company Name
                  <span>*</span>
                </label>
                <input
                  className={styles.input_field}
                  placeholder="Your Company Name"
                  type="text"
                  id="cname"
                  name="cname"
                  required
                  value={enteredCompanyName || ""}
                  onChange={(event) =>
                    setEnteredCompanyName(event.target.value)
                  }
                />
              </div>

              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="website">
                  Website URL
                  <span>*</span>
                </label>
                <input
                  className={styles.input_field}
                  placeholder="Your Website URL"
                  type="text"
                  id="website"
                  name="website"
                  required
                  value={enteredWebsiteUrl || ""}
                  onChange={(event) => setEnteredWebsiteUrl(event.target.value)}
                />
              </div>

              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="interested">
                  I am Interested In
                  <span>*</span>
                </label>
                <select
                  className={styles.input_field}
                  type="text"
                  id="interested"
                  name="interested"
                  required
                  value={interestedIn || ""}
                  onChange={(event) => setInterestedIn(event.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Web and Mobile App">
                    1. Web and Mobile App
                  </option>
                  <option value="Paid Ads">2. Paid Ads</option>
                  <option value="Social Media Management">
                    3. Social Media Management
                  </option>
                  <option value="Poster Design">4. Poster Design</option>
                  <option value="1 and 2">1 and 2</option>
                  <option value="1 and 3">1 and 3</option>
                  <option value="1 and 4">1 and 4</option>
                  <option value="2 and 3">2 and 3</option>
                  <option value="2 and 4">2 and 4</option>
                  <option value="3 and 4">3 and 4</option>
                  <option value="all">All</option>
                </select>
              </div>
            </div>

            <div className={styles.inputs_cont}>
              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="budget_range">
                  Budget range
                  <span>*</span>
                </label>
                <select
                  className={styles.input_field}
                  type="text"
                  id="budget_range"
                  name="budget_range"
                  required
                  value={budgetRange || ""}
                  onChange={(event) => setBudgetRange(event.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Under ₹30,000">Under ₹30,000</option>
                  <option value="Under ₹50,000">Under ₹50,000</option>
                  <option value="Under ₹100,000">Under ₹100,000</option>
                </select>
              </div>

              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="instagram">
                  Instagram Page URL
                  <span>*</span>
                </label>
                <input
                  className={styles.input_field}
                  placeholder="Instagram URL"
                  type="text"
                  id="instagram"
                  name="instagram"
                  required
                  value={instaUrl || ""}
                  onChange={(event) => setInstaUrl(event.target.value)}
                />
              </div>

              <div className={styles.form_group}>
                <label className={styles.label} htmlFor="description">
                  Message
                  <span>*</span>
                </label>
                <textarea
                  className={styles.input_field}
                  placeholder="Enter Your Message"
                  id="description"
                  name="description"
                  required
                  rows="6"
                  value={enteredDescription}
                  onChange={(event) =>
                    setEnteredDescription(event.target.value)
                  }
                />
              </div>
            </div>

            <div className={styles.submit_btn_cont}>
              <ButtonComponent
                handler={formSubmitHandler}
                text={"Submit"}
                indicator={btnLoader}
                disabled={btnLoader}
                style={styles.submit_button}
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default BookCallPage;
