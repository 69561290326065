import BannerContainerOne from "../homepage/components/bannerContainer/bannerContainerOne";
import HowWeHelpContainer from "./components/HowWeHelpContainer/howWeHelpContainer";
import FooterContainer from "./components/footerContainer/footerContainer";
import OurProductContainer from "./components/ourProductContainer/ourProductContainer";
import SkillsContainer from "./components/skillsContainer/skillsContainer";
import WhatWeDoContainer from "./components/whatWeDoContainer/whatWeDoContainer";
import WhoWeAreContainer from "./components/whoWeAreContainer/whoWeAreContainer";
import { motion } from "framer-motion";
import whatsAppIcon from "../../assets/icons/whatsappIcon.png";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import styles from "../../components/layout/main-navigation.module.css";

function Homepage() {
  const defaultMessage = encodeURIComponent(
    "Hey there! 😊 Interested in what you offer! Could you give me some details?"
  );
  const phoneNumber = "91 8667882137";

  const openWhatsApp = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${defaultMessage}`;
    window.open(whatsappLink, "_blank");
  };

  const whatWeDoRef = useRef(null);
  const ourProductRef = useRef(null);

  const scrollToWhatWeDo = () => {
    if (whatWeDoRef.current) {
      whatWeDoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToOurProduct = () => {
    if (ourProductRef.current) {
      ourProductRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Synerixs</title>
        <link rel="canonical" href="https://synerixs.com/" />
        <meta
          name="description"
          content="Optimize your digital footprint with our tailored marketing and development solutions"
        />
      </Helmet>
      <BannerContainerOne
        scrollToWhatWeDo={scrollToWhatWeDo}
        scrollToOurProduct={scrollToOurProduct}
      />
      <WhoWeAreContainer />
      <WhatWeDoContainer
        ref={whatWeDoRef}
        scrollToOurProduct={scrollToOurProduct}
      />
      <SkillsContainer />
      <HowWeHelpContainer />
      <OurProductContainer ref={ourProductRef} />
      <FooterContainer />
      <motion.a
        className={styles.whatsappIcon}
        href="#"
        onClick={openWhatsApp}
        whileHover={{
          scale: 1.05,
        }}
      >
        <motion.img
          src={whatsAppIcon}
          alt="WhatsApp icon"
          whileHover={{
            scale: 1.05,
          }}
        />
      </motion.a>
    </>
  );
}

export default Homepage;
