import howCircleImg from "../../../../assets/images/howCircle1.png";
import styles from "./howWeHelpContainer.module.css";
import commonStyles from "../../../mainStyles.module.css";
import { useState, useEffect } from "react";

function HowWeHelpContainer() {
  const [growth, setGrowth] = useState(1);
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      setGrowth((prevGrowth) => (prevGrowth % 3) + 1);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={styles.how_container}>
      <div className={styles.how_container_main}>
        <div
          className={`${commonStyles.column_flex_box} ${styles.how_container_sub}`}
        >
          <p>How Do We Drive Growth?</p>
          <p>
            We ignite growth by reaching, engaging, and converting audiences
            with targeted strategies and compelling content.
          </p>

          <div
            className={`${commonStyles.row_flex_box} ${styles.how_img_text_cont}`}
          >
            <div className={styles.how_img_cont}>
              <img src={howCircleImg} alt="how-digital-marketing_work" />
              {windowWidth > 849 && (
                <div
                  className={styles.reach_card}
                  style={{
                    borderColor: growth === 1 ? "#55e6a5" : "#fff",
                    color: growth === 1 ? "#55e6a5" : "#fff",
                  }}
                >
                  <span>Reach</span>
                </div>
              )}
              {windowWidth > 849 && (
                <div
                  className={styles.engage_card}
                  style={{
                    borderColor: growth === 2 ? "#55e6a5" : "#fff",
                    color: growth === 2 ? "#55e6a5" : "#fff",
                  }}
                >
                  <span>Engage</span>
                </div>
              )}
              {windowWidth > 849 && (
                <div
                  className={styles.convert_card}
                  style={{
                    borderColor: growth === 3 ? "#55e6a5" : "#fff",
                    color: growth === 3 ? "#55e6a5" : "#fff",
                  }}
                >
                  <span>Convert</span>
                </div>
              )}

              {windowWidth <= 849 && (
                <div
                  className={`${commonStyles.row_flex_box} ${styles.points_cont}`}
                >
                  <div
                    className={styles.reach_card_new}
                    style={{
                      borderColor: growth === 1 ? "#55e6a5" : "#fff",
                      color: growth === 1 ? "#55e6a5" : "#fff",
                    }}
                  >
                    <span>Reach</span>
                  </div>

                  <div
                    className={styles.engage_card_new}
                    style={{
                      borderColor: growth === 2 ? "#55e6a5" : "#fff",
                      color: growth === 2 ? "#55e6a5" : "#fff",
                    }}
                  >
                    <span>Engage</span>
                  </div>

                  <div
                    className={styles.convert_card_new}
                    style={{
                      borderColor: growth === 3 ? "#55e6a5" : "#fff",
                      color: growth === 3 ? "#55e6a5" : "#fff",
                    }}
                  >
                    <span>Convert</span>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`${commonStyles.column_flex_box} ${styles.how_text_cont}`}
            >
              {growth === 1 && (
                <ul className={`${commonStyles.column_flex_box}`}>
                  <li>
                    Expand your online presence to reach more potential
                    customers.
                  </li>
                  <li>
                    Target specific audiences across various digital platforms
                    with precision.
                  </li>
                  <li>
                    Increase brand visibility and awareness through strategic
                    online outreach efforts.
                  </li>
                </ul>
              )}

              {growth === 2 && (
                <ul className={`${commonStyles.column_flex_box}`}>
                  <li>
                    Capture audience attention with compelling content and
                    interactive experiences.
                  </li>
                  <li>
                    Foster meaningful connections through personalized
                    communication and community engagement.
                  </li>
                  <li>
                    Encourage active participation and dialogue to build
                    relationships and trust with your audience.
                  </li>
                </ul>
              )}

              {growth === 3 && (
                <ul className={`${commonStyles.column_flex_box}`}>
                  <li>
                    Drive desired actions such as purchases, sign-ups, or
                    inquiries effectively.
                  </li>
                  <li>
                    Guide prospects through the sales funnel with persuasive
                    messaging and optimized user experiences.
                  </li>
                  <li>
                    Maximize conversion rates by refining strategies based on
                    data insights and continuous testing
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWeHelpContainer;
