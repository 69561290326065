import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/buttonComponent/buttonComponent";
import styles from "../privacyPolicy/privacyPolicyPage.module.css";

function AboutUsPage() {
  const navigate = useNavigate();

  function backBtnHandler() {
    navigate("/");
  }

  return (
    <section className={styles.privacy_page_container}>
      <div className={styles.top_btn_cont}>
        <ButtonComponent
          text={"Back to home"}
          style={styles.btn}
          handler={backBtnHandler}
        />
      </div>
      <div className={styles.top_image_container}>
        <span>About Us</span>
        <span>
          Welcome to Synerixs - where innovation knows no limits, and
          possibilities are endless.
        </span>
      </div>
      <div className={styles.bottom_content_container}>
        <div className={styles.head_content_container}>
          <span>Our Story: Crafting Digital Excellence</span>
          <span>
            Welcome to Synerixs, where creativity meets innovation in the realm
            of digital marketing and beyond. Our journey began with a passion
            for helping businesses thrive in the digital age.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Our Mission: Redefining the Digital Experience</span>
          <span>
            At Synerixs, we are committed to pushing the boundaries of what's
            possible in digital marketing and development. Our mission is to
            empower businesses of all sizes to succeed in the ever-evolving
            digital landscape.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Meet the Team: The Faces Behind Synerixs</span>
          <span>
            Our team is comprised of dedicated individuals with diverse
            backgrounds and expertise, united by a shared vision of delivering
            exceptional results for our clients. Get to know the talented minds
            driving Synerixs forward.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Our Services: Elevating Your Digital Presence</span>
          <span>
            From SEO and social media management to custom app development and
            graphic design, we offer a comprehensive suite of services designed
            to elevate your brand's digital presence and drive tangible results.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Our Approach: Blending Creativity with Technology</span>
          <span>
            We believe in harnessing the power of both creativity and technology
            to create impactful digital experiences. By marrying cutting-edge
            tools with innovative strategies, we deliver solutions that resonate
            with your audience and propel your brand forward.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Our Values: Integrity, Transparency, Collaboration</span>
          <span>
            Integrity, transparency, and collaboration are not just buzzwords
            for us - they are the guiding principles that shape everything we
            do. We believe in honest communication, ethical practices, and
            working closely with our clients every step of the way.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>
            Why Choose Synerixs: Unmatched Expertise, Unparalleled Results
          </span>
          <span>
            With years of experience and a proven track record of success,
            Synerixs is the partner you can trust to help you achieve your
            digital goals. Our dedication to excellence sets us apart, ensuring
            that you receive nothing but the best from start to finish.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Our Promise: Delivering Excellence, Every Step of the Way</span>
          <span>
            At Synerixs, we are committed to delivering excellence in everything
            we do. From the initial consultation to the final deliverable, you
            can count on us to go above and beyond to exceed your expectations
            and deliver results that speak for themselves.
          </span>
        </div>

        <div className={styles.head_content_container}>
          <span>Get in Touch: Let’s Start Your Digital Journey Today</span>
          <span>
            Ready to take your brand to the next level? Get in touch with us
            today to learn more about our services and how we can help you
            succeed in the digital world. Together, let's turn your vision into
            reality and make your mark on the digital landscape.
          </span>
        </div>
      </div>
    </section>
  );
}

export default AboutUsPage;
